import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faListCheck,
  faList,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";

function BottomMenu({ auth }) {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const firstname = searchParams.get("firstname");

  const setActiveClass = (menu) => {
    if (menu === status) return "active text-primary";
  };
  if (localStorage.getItem("authenticated") && firstname === null) {
    return (
      <Navbar
        expand="lg"
        className="navbar navbar-light bg-white border navbar-expand fixed-bottom"
      >
        <ul className="navbar-nav nav-justified w-100">
          <li className="nav-item">
            <a
              className={`nav-link position-relative ${setActiveClass(
                "MY_NOT_STARTED"
              )}`}
              href="/transfers?status=MY_NOT_STARTED"
            >
              <div className="nav-icon">
                <FontAwesomeIcon icon={faClock} />
              </div>
              My To do
            </a>
          </li>
          {(localStorage.getItem("role") === "Administrateur" ||
            localStorage.getItem("role") === "Super Administrateur") && (
            <li className="nav-item">
              <a
                className={`nav-link position-relative ${setActiveClass(
                  "NOT_STARTED"
                )}`}
                href="/transfers?status=NOT_STARTED"
              >
                <div className="nav-icon">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                All To do
              </a>
            </li>
          )}
          <li className="nav-item">
            <a
              className={`nav-link position-relative ${setActiveClass(
                "STARTED"
              )}`}
              href="/transfers?status=STARTED"
            >
              <div className="nav-icon">
                <FontAwesomeIcon icon={faPlay} />
              </div>
              Started
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link position-relative ${setActiveClass(
                "DROPPED_OFF"
              )}`}
              href="/transfers?status=DROPPED_OFF"
            >
              <div className="nav-icon">
                <FontAwesomeIcon icon={faListCheck} />
              </div>
              Finished
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link position-relative ${setActiveClass(null)}`}
              href="transfers"
            >
              <div className="nav-icon">
                <FontAwesomeIcon icon={faList} />
              </div>
              All
            </a>
          </li>
        </ul>
      </Navbar>
    );
  }
}

export default BottomMenu;
