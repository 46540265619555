import React, { useEffect, useState } from "react";
import api from "../../utils/api";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import TransferRow from "./TransferRow"; // Import du nouveau composant
import "./Transfers.css";
import { useSwipe } from "./SwipeContext"; // Importer le contexte
import { PaymentMethodModal, choosePaymentMethod } from "./transferFunctions"; // Import de la modal pour les paiements
import moment from "moment"; // Importation de moment

function Transfers() {
  const [isLoading, setLoading] = useState(true);
  const [transfers, setTransfers] = useState([]);
  const [chauffeurs, setChauffeurs] = useState([]);
  const [selectedChauffeur, setSelectedChauffeur] = useState(null);
  const [selectedTransferForPayment, setSelectedTransferForPayment] =
    useState(null); // État pour la modal de paiement
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const { isSwiping } = useSwipe(); // Obtenir l'état du swipe
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const handleClosePaymentMethod = () => setShowPaymentMethod(false);

  const goToResa = (data) => {
    navigate("/transfers/" + data.IdResa, { state: data });
  };

  const setBgColor = (montant, etat, driver_name) => {
    if (Math.round(montant) === 0 || etat === "Annule") return "bg-danger"; // Si annulé ou montant à zéro, rouge
    if (etat === "Valide" && driver_name !== null) return "bg-white"; // Si l'état est valide et qu'un chauffeur est affecté, blanc
    if (etat === "Attente" || driver_name === null) return "bg-warning"; // Si en attente ou pas de chauffeur, jaune
    return "bg-white"; // Par défaut blanc si validé
  };

  const handleChauffeurSelect = (chauffeur) => () => {
    if (selectedChauffeur) {
      setSelectedChauffeur(null);
    } else setSelectedChauffeur(chauffeur);
  };

  const handleCourseClick = (course) => () => {
    if (isSwiping) {
      return;
    }
    if (selectedChauffeur) {
      assignDriverToCourse(course.IdResa, selectedChauffeur.id);
      setTransfers(
        transfers.map((t) =>
          t.IdResa === course.IdResa
            ? { ...t, driver_name: selectedChauffeur.name, Etat: "Valide" }
            : t
        )
      );
      setSelectedChauffeur(null);
    } else {
      goToResa(course);
    }
  };

  const fetchChauffeurs = async () => {
    try {
      const response = await api.get("/drivers");
      setChauffeurs(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des chauffeurs :", error);
    }
  };

  const fetchTransfers = async (status) => {
    try {
      let URL = "/transfers";
      if (status !== null) URL += "?status=" + status;
      const response = await api.get(URL);
      setTransfers(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des transferts :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChauffeurs();
    fetchTransfers(status);
  }, [status]);

  const assignDriverToCourse = async (courseId, driverId) => {
    try {
      const response = await api.post(`/transfers/${courseId}/assign-driver`, {
        driver_id: driverId,
      });
      fetchTransfers(status); // Appelle la fonction qui récupère les transferts
      console.log("Chauffeur assigné avec succès", response.data);
    } catch (error) {
      console.error("Erreur lors de l'assignation du chauffeur :", error);
    }
  };

  const unassignDriver = async (courseId, driverId) => {
    try {
      await api.delete(`/transfers/${courseId}/unassign-driver/${driverId}`);
      fetchTransfers(status); // Appelle la fonction qui récupère les transferts
      console.log("Chauffeur désassigné avec succès et transferts mis à jour");
    } catch (error) {
      console.error("Erreur lors de la désassignation du chauffeur :", error);
    }
  };

  // Fonction pour ouvrir la modal de paiement
  const handleOpenPaymentMethod = (transferId) => {
    setSelectedTransferForPayment(transferId); // Enregistre l'ID du transfert pour lequel la modal s'ouvre
    setShowPaymentMethod(true); // Affiche la modal
  };

  // Fonction pour regrouper les transferts par date
  const groupTransfersByDate = (transfers) => {
    return transfers.reduce((groupedTransfers, transfer) => {
      const date = moment(transfer.DateResa).format("YYYY-MM-DD"); // Utilisation de moment pour formater la date
      if (!groupedTransfers[date]) {
        groupedTransfers[date] = [];
      }
      groupedTransfers[date].push(transfer);
      return groupedTransfers;
    }, {});
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status" variant="success">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container fluid style={{ paddingBottom: "90px" }}>
      {(localStorage.getItem("role") === "Administrateur" ||
        localStorage.getItem("role") === "Super Administrateur") && (
        <Row>
          <Col>
            <div className="drivers_list">
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                {chauffeurs.map((chauffeur) => (
                  <div
                    key={chauffeur.id}
                    onClick={handleChauffeurSelect(chauffeur)}
                    className="driver_picto"
                    style={{
                      backgroundColor:
                        selectedChauffeur?.id === chauffeur.id
                          ? "#40cdf9"
                          : "#d9f6ff",
                    }}
                  >
                    {chauffeur.name}
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      )}

      <Row
        style={{
          paddingTop:
            localStorage.getItem("role") === "Administrateur" ||
            localStorage.getItem("role") === "Super Administrateur"
              ? "130px"
              : "",
        }}
      >
        <Col>
          {Object.entries(groupTransfersByDate(transfers)).map(
            ([date, transfersOnDate]) => (
              <React.Fragment key={date}>
                {/* Afficher la date comme un séparateur */}
                <div className="date-separator">
                  <h6 className="text-white text-left p-1">
                    {new Date(date).toLocaleDateString("fr-FR")}
                  </h6>
                </div>

                {/* Afficher les transferts pour cette date */}
                {transfersOnDate.map((course) => (
                  <TransferRow
                    key={course.IdResa + "-" + course.IdChauffeur}
                    course={course}
                    unassignDriver={unassignDriver}
                    handleCourseClick={handleCourseClick}
                    setBgColor={setBgColor}
                    handleOpenPaymentMethod={() =>
                      handleOpenPaymentMethod(course.IdResa)
                    }
                  />
                ))}
              </React.Fragment>
            )
          )}
        </Col>
      </Row>

      {/* Modal de paiement PAB */}
      <PaymentMethodModal
        show={showPaymentMethod}
        handleClose={handleClosePaymentMethod}
        transferId={selectedTransferForPayment} // Passe l'ID du transfert à la modal
        choosePaymentMethod={choosePaymentMethod}
      />
    </Container>
  );
}

export default Transfers;
