import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
function Menu({ auth }) {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("authenticated");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("role");
    navigate("/");
  };

  if (localStorage.getItem("authenticated"))
    return (
      <Navbar expand="lg" className="navbar navbar-dark bg-dark sticky-top p-0">
        <Container>
          <Button className="btn-light" onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
              />
            </svg>
          </Button>
          <Navbar.Brand href="/">
            <img
              style={{ height: "4rem" }}
              src="https://www.odysseetransfer.com/wp-content/themes/Odyssee_transfer/images/logo_white_250.png"
              alt="odysseetransfer"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/transfers">Transferts</Nav.Link>
              <Nav.Link href="/account">Mon compte</Nav.Link>
              <Nav.Link>
                <Button variant="danger" onClickCapture={logout}>
                  Logout
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}

export default Menu;
