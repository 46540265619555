import React from "react";
// import { setupNotifications } from "./config/firebase";
// import {
//   toastNotification,
//   sendNativeNotification,
// } from "./utils/notificationHelpers";
// import useVisibilityChange from "./hook/useVisibilityChange";
// import { register } from "./service/serviceWorker";
import PrivateRoute from "./PrivateRoute";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// Components
import "./App.css";
import Login from "./components/Login/Login";
import Transfers from "./components/Transfers/Transfers";
import Transfer from "./components/Transfers/Transfer";
import Menu from "./components/Global/Menu";
import BottomMenu from "./components/Global/BottomMenu";
import SignName from "./components/Transfers/SignName";
import Account from "./components/Account/Account";
import { SwipeProvider } from "./components/Transfers/SwipeContext"; // Importer le provider

const App = () => {
  // const isForeground = useVisibilityChange();

  // useEffect(() => {
  //   setupNotifications((message) => {
  //     if (isForeground) {
  //       // App is in the foreground, show toast notification
  //       toastNotification({
  //         title: "test",
  //         description: "body",
  //         status: "info",
  //       });
  //     } else {
  //       // App is in the background, show native notification
  //       sendNativeNotification({
  //         title: "test",
  //         body: "test",
  //       });
  //     }
  //   });
  // }, [isForeground]);

  return (
    <Router>
      <Menu />
      <Routes>
        <Route
          path="transfers"
          element={
            <PrivateRoute auth={localStorage.getItem("authenticated")}>
              <SwipeProvider>
                <Transfers />
              </SwipeProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="transfers/:id"
          element={
            <PrivateRoute>
              <Transfer />
            </PrivateRoute>
          }
        />
        <Route
          path="/sign-name"
          element={
            <PrivateRoute>
              <SignName />
            </PrivateRoute>
          }
        />
        <Route
          path="/account"
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate replace to="/transfers" />} />
      </Routes>
      <BottomMenu />
    </Router>
  );
};
export default App;
