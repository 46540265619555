import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import api from "../../utils/api";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useParams } from "react-router-dom";
import "moment/locale/fr"; // add this line below your moment import
import Moment from "react-moment";
import {
  onTheWay,
  arrivedToPick,
  startTheRide,
  choosePaymentMethod,
  stopTheRide,
  PaymentMethodModal,
} from "./transferFunctions";

function Transfer() {
  const params = useParams();
  const [transfer, setTransfer] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [problems, setProblems] = useState("");
  const [state, setState] = useState("");
  const [status, setStatus] = useState();
  const [isFlight, setIsFlight] = useState(false);
  const [isTrain, setIsTrain] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const handleClosePaymentMethod = () => setShowPaymentMethod(false);

  const getTransfer = async (id) => {
    await api.get("/transfers/" + id).then((response) => {
      setTransfer(response.data);
      setStatus(response.data.status);
      if (
        response.data.AdresseDep.toLowerCase().includes("airport") ||
        response.data.AdresseDep.toLowerCase().includes("aéroport") ||
        response.data.NVol !== ""
      )
        setIsFlight(true);

      if (
        response.data.AdresseDep.toLowerCase().includes("gare") ||
        response.data.AdresseDep.toLowerCase().includes("station")
      )
        setIsTrain(true);
      setProblems(response.data.comments ?? "");
      setState(response.data.Etat ?? "");
    });
    setLoading(false);
  };

  useEffect(() => {
    getTransfer(params.id);
  }, [params]);

  const Callto = ({ phone, children }) => {
    return (
      <p className="fw-bold">
        <a className="text-decoration-none" href={`tel:${phone}`}>
          {children}
        </a>
        <a
          className="text-decoration-none"
          style={{ paddingLeft: "10px" }}
          href={`whatsapp://send?phone=${phone}`}
        >
          <svg
            width="20"
            height="20"
            fill="#38761D"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
          </svg>
        </a>
      </p>
    );
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status" variant="success">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const OnTheWayButton = () => {
    if (status === "NOT_STARTED")
      return (
        <Row className="p-2 sticky-bottom" style={{ bottom: "80px" }}>
          <Button
            variant="primary"
            size="lg"
            onClick={() => onTheWay(transfer.IdResa, setStatus)}
          >
            On the way
          </Button>
        </Row>
      );
  };

  const ArrivedToPickButton = () => {
    if (status === "ON_THE_WAY")
      return (
        <Row className="p-2 sticky-bottom" style={{ bottom: "80px" }}>
          <Button
            variant="info"
            size="lg"
            onClick={() => arrivedToPick(transfer.IdResa, setStatus)}
          >
            Arrived to pick up
          </Button>
        </Row>
      );
  };

  const StartTheRideButton = () => {
    if (status === "ARRIVED")
      return (
        <Row className="p-2 sticky-bottom" style={{ bottom: "80px" }}>
          <Button
            variant="success"
            size="lg"
            onClick={() => startTheRide(transfer.IdResa, setStatus)}
          >
            Client picked up
          </Button>
        </Row>
      );
  };

  const StopTheRideButton = () => {
    if (status === "PICKED_UP")
      return (
        <Row className="p-2 sticky-bottom" style={{ bottom: "80px" }}>
          <Button
            variant="warning"
            size="lg"
            onClick={() =>
              stopTheRide(
                transfer.IdResa,
                transfer.Paiement,
                setShowPaymentMethod,
                setStatus
              )
            }
          >
            Client dropped off
          </Button>
        </Row>
      );
  };

  const FinishedButton = () => {
    if (status === "DROPPED_OFF")
      return (
        <Row className="p-2 row">
          <Button variant="success" size="lg">
            Finished
          </Button>
        </Row>
      );
  };

  const reportProblem = () => {
    setShow(true);
  };

  const saveProblems = () => {
    api
      .post("/transfers/" + transfer.IdResa + "/problems", {
        comments: problems,
        state: state,
      })
      .then((response) => {
        setShow(false);
      });
  };
  const Comments = () => {
    if (transfer.Commentaire) {
      return (
        <Row className="p-2">
          <Card className="shadow bg-white rounded">
            <Card.Header>Comments</Card.Header>
            <Card.Body>
              <Card.Text className="fw-bold">{transfer.Commentaire}</Card.Text>
            </Card.Body>
          </Card>
        </Row>
      );
    }
  };

  const PriceInformation = () => {
    if (
      transfer.Paiement !== "Virement" ||
      localStorage.getItem("role") === "Administrateur" ||
      localStorage.getItem("role") === "Super Administrateur"
    ) {
      return (
        <Row className="p-2">
          <Card className="shadow bg-white rounded">
            <Card.Header>Paiement</Card.Header>
            <Card.Body>
              <p>
                Mode de paiement :
                <span className="fw-bold">{transfer.Paiement}</span>
              </p>
              <p>
                Prix : <span className="fw-bold">{transfer.Prix}€</span>
              </p>
            </Card.Body>
          </Card>
        </Row>
      );
    }
  };

  const Additional = () => {
    if (isFlight) {
      return (
        <Row className="p-2">
          <Card className="shadow bg-white rounded">
            <Card.Header>Additional informations</Card.Header>
            <Card.Body>
              <Container>
                <Row>
                  <Col lg xs="6">
                    <p>Flight arrival time</p>
                    <p className="fw-bold">{transfer.HeureResa}</p>
                  </Col>
                  <Col lg xs="6">
                    <p>Flight number</p>
                    <p className="fw-bold">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`//www.google.fr/search?q=${transfer.NVol}`}
                      >
                        {transfer.NVol}
                      </a>
                    </p>
                  </Col>
                  <Col lg xs="6">
                    <p>Flight terminal</p>
                    <p className="fw-bold">{transfer.Terminal}</p>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Row>
      );
    }
    if (isTrain) {
      return (
        <Row className="p-2">
          <Card className="shadow bg-white rounded">
            <Card.Header>Additional informations</Card.Header>
            <Card.Body>
              <Container>
                <Row>
                  <Col>
                    <p>Train arrival time</p>
                    <p className="fw-bold">{transfer.HeureResa}</p>
                  </Col>
                  <Col>
                    <p>Train number</p>
                    <p className="fw-bold">{transfer.NTrain}</p>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Row>
      );
    }
  };
  return (
    <Container fluid style={{ paddingBottom: "90px" }}>
      <Row>
        <div className="d-flex justify-content-center">
          <h3 className="fw-bold fs-5 text-light">
            Ride details {transfer.IdResa}
          </h3>
        </div>
      </Row>
      <Row className="p-2">
        <Card className="shadow bg-white rounded">
          <Card.Header>Date </Card.Header>
          <Card.Body>
            <Card.Text className="fw-bold">
              <Moment format="LL">{transfer.DateResa}</Moment> -{" "}
              {transfer.HeureResa}
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
      <Row className="p-2">
        <Card className="shadow bg-white rounded">
          <Card.Header>Pick-up location</Card.Header>
          <Card.Body>
            <Card.Text className="fw-bold">
              {" "}
              <a
                href={`https://www.waze.com/ul?ll=${transfer.LatDep}%2C${transfer.LngDep}&navigate=yes&zoom=17s`}
              >
                {transfer.AdresseDep}
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
      <Row className="p-2">
        <Card className="shadow bg-white rounded">
          <Card.Header>Drop-off location</Card.Header>
          <Card.Body>
            <Card.Text className="fw-bold">
              <a
                href={`https://www.waze.com/ul?ll=${transfer.LatArr}%2C${transfer.LngArr}&navigate=yes&zoom=17s`}
              >
                {transfer.AdresseArr}
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
      </Row>
      <Row className="p-2">
        <Card className="shadow bg-white rounded">
          <Card.Header>Passenger informations</Card.Header>
          <Card.Body>
            <Container>
              <Row>
                <Col>
                  <p>Passenger name</p>
                  <p className="fw-bold">
                    <a
                      href={`/sign-name?lastname=${transfer.NomResa}&firstname=${transfer.PrenomResa}`}
                    >
                      {transfer.NomResa} {transfer.PrenomResa}
                    </a>
                  </p>
                </Col>
                <Col>
                  <p>Passenger contact</p>
                  <div>
                    <Callto phone={transfer.NumTel}>{transfer.NumTel}</Callto>
                  </div>{" "}
                </Col>
                <Col>
                  <p>Number of passagers</p>
                  <p className="fw-bold">{transfer.Pax}</p>
                </Col>
                {localStorage.getItem("role") === "Administrateur" && (
                  <Col>
                    <p>Vehicle type</p>
                    <p className="fw-bold">{transfer.Type}</p>
                  </Col>
                )}
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </Row>
      <PriceInformation />
      <Additional />
      <Comments />
      <OnTheWayButton />
      <ArrivedToPickButton />
      <StartTheRideButton />
      <StopTheRideButton />
      <FinishedButton />
      <Row className="p-2">
        <Button variant="danger" size="lg" onClick={reportProblem}>
          Signal a problem
        </Button>
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Signal the problem</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="etat">
              <Form.Label>Etat</Form.Label>
              <Form.Select
                aria-label=""
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              >
                <option></option>
                <option value="No Show">No Show</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="problems">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={problems}
                onChange={(e) => setProblems(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveProblems}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <PaymentMethodModal
        transferId={transfer.IdResa}
        show={showPaymentMethod}
        handleClose={handleClosePaymentMethod}
        choosePaymentMethod={choosePaymentMethod}
      />
    </Container>
  );
}

export default Transfer;
