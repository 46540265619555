import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import "./Account.css";
import api from "../../utils/api";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Account() {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const [accountState, setAccountState] = useState({
    password_confirm: "",
    password: "",
  });
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const getAccount = async () => {
    setLoading(true);
    await api.get("/account").then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAccount();
  }, []);

  const [errors, setErrors] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    // perform all neccassary validations
    if (accountState.password !== accountState.password_confirm) {
      alert("Passwords don't match");
    } else {
      api
        .post(`${REACT_APP_API_URL}/account`, {
          password_confirm: accountState.password_confirm,
          password: accountState.password,
        })
        .then((res) => {
          if (res.status === 200) {
            alert("Password change. Please reconnect");
            localStorage.removeItem("authenticated");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("reset_password");
            localStorage.removeItem("role");
            localStorage.removeItem("user_id");
            navigate("/");
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401)
              setErrors("Mot de passe incorrect");
            else setErrors("Please try again.");
          }
        });
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" role="status" variant="success">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container key="container">
      <Form onSubmit={handleSubmit} key="form">
        <div>
          <h3 className="text-white text-center">Mon compte</h3>
          {data?.map((user) => (
            <div key={user.Id}>
              <div className="form-group mt-3">
                <label className="text-white">Email address</label>
                <p className="text-white">{user.email}</p>
              </div>
              <div className="form-group mt-3">
                <label className="text-white">Last name</label>
                <p className="text-white">{user.Nom}</p>
              </div>
              <div className="form-group mt-3">
                <label className="text-white">First name</label>
                <p className="text-white">{user.Prenom}</p>
              </div>

              <div className="form-group mt-3">
                <label className="text-white">Password</label>
                <input
                  type="password"
                  required
                  className="form-control mt-1"
                  placeholder="Enter password"
                  onChange={(event) => {
                    const value = event.target.value;
                    setAccountState({ ...accountState, password: value });
                  }}
                />
              </div>
              <div className="form-group mt-3">
                <label className="text-white">Confirm Password</label>
                <input
                  type="password"
                  required
                  className="form-control mt-1"
                  placeholder="Confirm password"
                  onChange={(event) => {
                    const value = event.target.value;
                    setAccountState({
                      ...accountState,
                      password_confirm: value,
                    });
                  }}
                />
              </div>
            </div>
          ))}
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
          <p>{errors}</p>
        </div>
      </Form>
    </Container>
  );
}

export default Account;
