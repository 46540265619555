import React, { useState, useEffect } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import "./Login.css";

function Login() {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const [loginState, setLoginState] = useState({
    email: "",
    password: "",
  });

  const [authenticated, setauthenticated] = useState(
    localStorage.getItem("authenticated") || false
  );
  const [errors, setErrors] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (authenticated) navigate("/transfers");
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`${REACT_APP_API_URL}/auth`, {
        email: loginState.email,
        password: loginState.password,
      })
      .then((res) => {
        if (res.status === 200) {
          const { accessToken, refreshToken, role, reset_password, user_id } =
            res.data;
          setauthenticated(true);
          localStorage.setItem("authenticated", true);
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("role", role);
          localStorage.setItem("reset_password", reset_password);
          localStorage.setItem("user_id", user_id);
          navigate("/transfers?status=MY_NOT_STARTED");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) setErrors("Mot de passe incorrect");
          else setErrors("Please try again.");
        }
      });
  };

  return (
    <Container className="Auth-form-container">
      <Form className="Auth-form" onSubmit={handleSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Odyssee Transfer</h3>

          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              required
              className="form-control mt-1"
              placeholder="Enter email"
              onChange={(event) => {
                const value = event.target.value;
                setLoginState({ ...loginState, email: value });
              }}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              required
              className="form-control mt-1"
              placeholder="Enter password"
              onChange={(event) => {
                const value = event.target.value;
                setLoginState({ ...loginState, password: value });
              }}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Log In
            </button>
          </div>
          <p>{errors}</p>
        </div>
      </Form>
    </Container>
  );
}

export default Login;
