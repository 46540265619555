// SwipeContext.js
import React, { createContext, useState, useContext } from "react";

const SwipeContext = createContext();

export const useSwipe = () => useContext(SwipeContext);

export const SwipeProvider = ({ children }) => {
  const [isSwiping, setIsSwiping] = useState(false);

  return (
    <SwipeContext.Provider value={{ isSwiping, setIsSwiping }}>
      {children}
    </SwipeContext.Provider>
  );
};
