import { useSearchParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

function SignName() {
  const [searchParams] = useSearchParams();
  const firstname = searchParams.get("firstname");
  const lastname = searchParams.get("lastname");

  return (
    <Container>
      <Row>
        <Col xl={12}>
          <div class="d-flex flex-column vh-100">
            <div className="d-flex flex-grow-1 justify-content-center align-items-center flex-column">
              <p style={{ color: "white", fontSize: "9vw" }}>{lastname}</p>
              <p style={{ color: "white", fontSize: "9vw" }}>{firstname}</p>
            </div>
            <div className="d-flex flex-grow-1 justify-content-center align-items-center"></div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SignName;
